import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faSyncAlt, faFolderOpen } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"

import Seo from "../components/seo"

const homePage = ({ data }) => (
<Layout>
  <Seo />
  <section className="hero">
    <StaticImage
      src="../images/hero.jpg"
      alt=""
      layout="fullWidth"
    />
    <div className="catch">
      <h1>Stay hungry. Stay foolish.</h1>
      <p>満足するな、常に挑戦しろ ― スティーブ・ジョブズ</p>
    </div>
    {/* <div className="wave">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 229.5" fill="#fff"><path d="M1369,6.3C1222.5-12.2,1189.5,8,919.2,96.6C665,179.8,160,141.7-2,53.1v150l1371-14.2V6.3z" opacity=".53" /><path d="M1369 229.5V55.8c-9.5-2.4-19.2-4.4-28.9-5.8-196.9-29.9-203.4-15.8-503.9 82.6-219.8 72-627.6 53.2-838.2-10.5v107.4h1371z" /></svg>
    </div> */}
  </section>
  {/* <section className="food">
    <div className="container">
      <h2 className="bar">Food <span>Essence</span></h2>
      <div className="details">
        <div className="detail">
          <Image filename="fruit.jpg" alt="" />
          <h3>フルーツ</h3>
          <p>FRUIT</p>
          <p>甘くてすっぱくておいしい果実たち。<br />旬のフルーツを満喫します。</p>
        </div>
        <div className="detail">
          <Image filename="grain.jpg" alt="" />
          <h3>穀物</h3>
          <p>GRAIN</p>
          <p>食事の基本となる穀物。<br />毎日の活動のエネルギー源になります。</p>
        </div>
        <div className="detail">
          <Image filename="beverage.jpg" alt="" />
          <h3>飲み物</h3>
          <p>BEVERAGE</p>
          <p>リラックスするのに欠かせない飲み物。<br />お気に入りの一杯はありますか？</p>
        </div>
      </div>
    </div>
  </section> */}
  {/* <section className="photo">
    <h2 className="sr-only">Photo</h2>
    <Image filename="berry.jpg" alt="赤く熟したベリー" style={{ height: "100%"}} />
  </section> */}
  <section>
    <div className="container">
      <h1 className="sr-only">RECENT POSTS</h1>
      <div className="posts">
        {data.allContentfulBlogPost.edges.map(({ node }) =>(
          <article className="post" key={node.id}>
            <Link to={`/blog/post/${node.slug}/`}>
              <figure>
                <GatsbyImage
                  image={node.eyecatch.gatsbyImageData}
                  alt={node.eyecatch.description}
                />
              </figure>
              <aside>
                <time dateTime={node.updatedAt > node.publishDate ? node.updatedAt : node.publishDate}>
                  <FontAwesomeIcon icon={node.updatedAt > node.publishDate ? faSyncAlt : faClock} />
                  {node.updatedAt > node.publishDate ? node.updatedAtJP : node.publishDateJP}
                </time>
                <div className="cat">
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <ul>
                    {node.category.map(cat => (
                      <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                    ))}
                  </ul>
                </div>
              </aside>
              <h3>{node.title}</h3>
            </Link>
          </article>
        ))}
        <div className="allpost">
          <p><Link to={`/blog/`}>全ての記事一覧</Link></p>
        </div>
      </div>
    </div>
  </section>
</Layout>
)

export const query = graphql`
query {
  allContentfulBlogPost(
    sort: {fields: publishDate, order: DESC}
    skip: 0
    limit: 4
  ) {
    edges {
      node {
        title
        publishDateJP:publishDate(formatString: "YYYY年MM月DD日")
        publishDate
        updatedAtJP:updatedAt(formatString: "YYYY年MM月DD日")
        updatedAt
        category {
          category
          categorySlug
          id
        }
        id
        slug
        eyecatch {
          gatsbyImageData(layout: CONSTRAINED)
          description
        }
      }
    }
  }
}
`

export default homePage